import React, { FC } from "react"
import { graphql } from "gatsby"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import { JsonLd } from "gatsby-plugin-next-seo"
import * as styles from "./styles.module.scss"
import LogosList from "src/components/LogosList"
import GetStarted from "src/components/Banners/GetStarted"
import CaseStudiesCards from "src/components/CaseStudiesCards"
import LogosGrid from "src/components/LogosGrid"
import Img from "gatsby-image"
import Reviews from "src/components/Reviews"
import { useSignUp } from "src/hooks/useSignUp"

const IndexLayout: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const signUp = useSignUp(texts)

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      className={styles.main}
    >
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@id": "https://www.getflow.com/#website",
          "@type": "WebSite",
          url: "https://www.getflow.com/",
          name: "Flow",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@id": "https://www.getflow.com/#webpage",
          "@type": "WebPage",
          name: "Flow",
          description:
            "Flow is an all-in-one tool that unleashes your team’s potential. It brings together your tasks, projects, timelines, files, and conversations, and integrates with your favorite tools, to help you achieve more.",
          datePublished: "2020-02-14T20:12:00+00:00",
        }}
      />

      <div className={`content-wrapper ${styles.home}`}>
        <h1 className={styles.title}>
          {texts.header} <span> {texts.headerBlue}</span>
        </h1>
        <p className={`${styles.subtitle} ${styles.bold}`}>{texts.subtitle}</p>
        <p className={styles.subtitle}>{texts.description}</p>
        <div className={`${styles.separatorText} ${styles.error}`}>
          {signUp.status === "error" ? signUp.error : ""}
        </div>
        <form
          className={`${styles.contentInput} ${signUp.status === "error" &&
            styles.error}`}
          {...signUp.formProps}
        >
          <input
            className={styles.emailInput}
            type="text"
            placeholder={texts.placeholderEmail}
            {...signUp.inputProps}
            autoFocus={true}
          />
          <button
            className={`button button-second ${styles.emailButton}`}
            type="submit"
            {...signUp.buttonProps}
          >
            {texts.signUp}
          </button>
        </form>
        <div className={styles.offerDescription}>
          <span>{texts.freeFor30Days}</span>
          <span>{texts.creditCard}</span>
          <span>{texts.cancel}</span>
        </div>
        <LogosList text={texts.logosTitle} className={styles.logosList} />
        <Img
          fluid={texts.image.childImageSharp.fluid}
          style={{ height: "100%", width: "100%" }}
          className={styles.image}
        />
        <h2 className={styles.titleH2}>{texts.logoGridTitle}</h2>
        <p className={styles.subtitle}>{texts.logoGridSubtitle}</p>
        <LogosGrid className={styles.logoGrid} />
      </div>
      <Reviews />
      <div className={`content-wrapper ${styles.caseStudiesContainer}`}>
        <h2 className={styles.titleH2}>{texts.caseStudiesTitle}</h2>
        <p className={styles.subtitle}>{texts.caseStudiesSubtitle}</p>
        <CaseStudiesCards mainCard="middle" className={styles.caseStudies} />
        <GetStarted />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        headerBlue
        subtitle
        description
        button
        logosTitle
        logoGridTitle
        logoGridSubtitle
        caseStudiesTitle
        caseStudiesSubtitle
        invalidEmailError
        unknownSignUpError
        freeFor30Days
        creditCard
        cancel
        signUp
        placeholderEmail
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1084) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default IndexLayout
